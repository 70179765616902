<template>
  <div class="page-container-new">
    <page-loading v-if="state.pageLoading"></page-loading>
    <div v-if="!state.pageLoading">
      <page-header
        title="공지사항"
        description="빅크에서 크리에이터분들께 알립니다."
      ></page-header>
      <div class="tab-wrapper">
        <button-tab
          text="전체"
          :active="state.activeAllNotices"
          @click="actions.updateTab('all')"
        ></button-tab>
        <button-tab
          text="필독 공지"
          :active="state.activeMustNotices"
          @click="actions.updateTab('must')"
        ></button-tab>
        <button-tab
          text="업데이트"
          :active="state.activeUpdatedNotices"
          @click="actions.updateTab('updated')"
        ></button-tab>
      </div>

      <div class="content-header">
        <h4>
          {{ `공지사항 ${state.pagination.total}개` }}
        </h4>

        <input-search
          v-model="state.q"
          class="search-wrapper"
          shape="round"
          placeholder="검색어를 입력해주세요."
          search-type="pressEnter"
          @search="actions.searchNotices()"
        ></input-search>
      </div>

      <div class="notice-table-header sub-text-s3 text-gray-second">
        <span class="column1">카테고리</span>
        <span class="column2">제목</span>
        <span class="column3">등록일</span>
      </div>

      <ul class="notice-list">
        <li
          v-for="notice in state.notices"
          :key="`notice-${notice.resourceId}`"
        >
          <div
            class="notice-card"
            @click="actions.goToNoticeDetail(notice.resourceId)"
          >
            <p
              class="notice-type sub-text-s2"
              :class="notice.isMust ? 'text-blue-50' : 'text-gray-second'"
            >
              {{ notice.isMust ? "필독 공지" : "업데이트" }}
            </p>
            <p class="title sub-text-s2">{{ notice.title }}</p>
            <p class="created-at sub-text-s2 text-gray-second">
              {{ dateFormat(notice.createdAt) }}
            </p>
          </div>
        </li>
      </ul>

      <div v-if="state.pagination.lastPage !== 1">
        <pagination
          class="pagination"
          :pagination="state.pagination"
          @getPageData="(page) => actions.getPageData(page)"
        ></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../../../components/console/headers/PageHeader";
import ButtonTab from "../../../components/console/buttons/ButtonTab";
import { computed, onBeforeMount, reactive } from "vue";
import { useStore } from "vuex";
import InputSearch from "../../../components/console/inputs/InputSearch";
import PageLoading from "../../../components/console/loadings/PageLoading";
import Pagination from "../../../components/console/pagination/Pagination";
import { dateFormat } from "@/helper/date";
import { useRouter } from "vue-router";

export default {
  name: "Notices",
  components: { Pagination, PageLoading, InputSearch, ButtonTab, PageHeader },
  setup() {
    const store = useStore();
    const router = useRouter();

    const state = reactive({
      pageLoading: true,
      tab: "all", // all, must, updated
      q: "",
      activeAllNotices: computed(() => {
        return state.tab === "all";
      }),
      activeMustNotices: computed(() => {
        return state.tab === "must";
      }),
      activeUpdatedNotices: computed(() => {
        return state.tab === "updated";
      }),
      notices: computed(() => {
        return store.getters["notices/notices"];
      }),
      pagination: computed(() => {
        console.log(store.getters["notices/noticesMeta"].pagination);
        return store.getters["notices/noticesMeta"].pagination;
      }),
    });

    onBeforeMount(async () => {
      await getNotices();
      state.pageLoading = false;
    });

    const getNotices = async (page) => {
      let payload = {
        isMust: state.tab === "all" ? null : state.tab === "must",
        q: state.q,
      };

      if (page) {
        payload.page = page;
      }

      console.log(payload);
      await store.dispatch("notices/getNotices", {
        data: payload,
      });
    };

    const actions = {
      updateTab: (tabName) => {
        if (tabName === state.tab) {
          return;
        } else {
          state.tab = tabName;
          getNotices();
        }
        state.tab = tabName;
      },
      searchNotices: () => {
        getNotices();
      },
      goToNoticeDetail: (noticeResourceId) => {
        router.push({
          name: "console.notices.show",
          params: { noticeResourceId: noticeResourceId },
        });
      },
      getPageData: async (page) => {
        state.pageLoading = true;
        await getNotices(page);
        state.pageLoading = false;
      },
    };

    return { state, actions, dateFormat };
  },
};
</script>

<style src="./style.css" scoped></style>
